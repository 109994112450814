import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi!`}</h1>
    <p>{`Welcome to my personal website! I am a full-time software engineer passionate about traveling, nature and various sport activities such as CrossFit, Hiking, Surfing, Skiing and Yoga.`}</p>
    <p>{`On this pages I write about my professional and personal life experiences. Feel free to contact me on social media if you want to chat about some projects/ideas.`}</p>
    <p><a parentName="p" {...{
        "href": "/mission"
      }}>{`What is it all about?`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      